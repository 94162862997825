import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import ReduxToastr from 'react-redux-toastr'
export default ({ children, initialState = {} }) => {
  const store = createStore(
    reducers,
    initialState,
    // compose(
    //   applyMiddleware(reduxThunk),
    //   window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //     window.__REDUX_DEVTOOLS_EXTENSION__()
    // )
    applyMiddleware(reduxThunk),
  );
  return <Provider store={store}>
  {children}
  <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick/>
  </Provider>;
};

// lets test if it works