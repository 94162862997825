import { LOGIN } from "../constants";
import { isValidSession, getSession } from "../helpers/session"
const initialState = {
  isSuperAdmin:getSession().sessionStorage.isSuperAdmin,
  isReady: isValidSession() ,
  isLoggedIn: isValidSession(),
  user: getSession().sessionStorage.user,
  accessToken: getSession().sessionStorage.accessToken,
  refreshToken: getSession().sessionStorage.refreshToken
}


export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      console.log("auth reducer",action.payload)
      return { isReady: true, ...action.payload.data, isLoggedIn: true };
    default:
      return state;
  }
}
