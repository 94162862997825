export function createSession(response) {
  sessionStorage.setItem('jwtAccessToken', response.data.accessToken);
  sessionStorage.setItem('jwtRefreshToken', response.data.refreshToken);
  sessionStorage.setItem('user', JSON.stringify(response.data.user));
  sessionStorage.setItem('isSuperAdmin', JSON.stringify(response.data.user.isSuperAdmin));


  localStorage.setItem('jwtAccessToken', response.data.accessToken);
  localStorage.setItem('jwtRefreshToken', response.data.refreshToken);
  localStorage.setItem('user', JSON.stringify(response.data.user));
  localStorage.setItem('isSuperAdmin', JSON.stringify(response.data.user.isSuperAdmin));
}

export function getSession(){
  return {
    sessionStorage:{
      accessToken: sessionStorage.getItem('jwtAccessToken'),
      refreshToken: sessionStorage.getItem('jwtRefreshToken'),
      user: sessionStorage.getItem('user'),
      isSuperAdmin: sessionStorage.getItem('isSuperAdmin')
    },
    localStorage: {
      accessToken: localStorage.getItem('jwtAccessToken'),
      refreshToken: localStorage.getItem('jwtRefreshToken'),
      user: localStorage.getItem('user'),
      isSuperAdmin: localStorage.getItem('isSuperAdmin')
    }
  }
}
export function isValidSession(){
  return (sessionStorage.getItem('jwtAccessToken') || localStorage.getItem('jwtAccessToken')) ? true : false
}

export function  destroySession(){
  sessionStorage.removeItem('jwtAccessToken');
  sessionStorage.removeItem('jwtRefreshToken');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('isSuperAdmin');

  localStorage.removeItem('jwtAccessToken');
  localStorage.removeItem('jwtRefreshToken');
  localStorage.removeItem('isSuperAdmin');
  localStorage.removeItem('user');
}