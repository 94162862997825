import React, { Component } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { isValidSession } from "./helpers/session";
import './App.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { axiosMiddleware } from './middleware/axios';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Entrance/login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const AccountVerfied = React.lazy(() => import('./views/Pages/Account/verified'));
axiosMiddleware();
const createBrowserHistory = require("history").createBrowserHistory

export const history = createBrowserHistory()
class App extends Component {

  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/account-verified" name="Verify Account" render={props => <AccountVerfied {...props} />} />
            <PrivateRoute path="/" name="Home" component={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;

function PrivateRoute({ component: Component, ...rest }) {
  console.log('isValidSession', isValidSession())
  // console.log(rest)
  return (
    <Route
      {...rest}
      render={(props) => isValidSession() === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

