import { history } from '../App'
import { destroySession } from "../helpers/session";
import axios from "axios";
import { toastr } from 'react-redux-toastr';
export const axiosMiddleware = () => {
  axios.interceptors.response.use((response) => {
    return response;
  
  }, err => {
    console.log('[middleware][axios]Error From API', err);
    try{
      if (err.response.status === 401) {
        destroySession();
        history.push("/login");
        console.log('UNAUTHORIZED Error', JSON.stringify(err));
        toastr.error('UNAUTHORIZED', "Session Expired")
        return Promise.reject([{error:'UNAUTHORIZED', message: "Session Expired"}])
      }else if (err.response.status === 400) {
        console.log('Bad Request', JSON.stringify(err.response));
        err.response.data.problems.map(er => toastr.error(err.response.data.code, er))
        return Promise.reject(err.response)
      }
      else if (err.response.status === 403) {
        console.log('Access Denied Request', JSON.stringify(err.response));
        toastr.error('Access Denied', err.response.data)
        history.push("/access-denied");
        return Promise.reject(err.response)
      }
      else if (err.response.status === 404) {
        console.log('Not Found Error', JSON.stringify(err.response));
        err.response.data.problems.map(er => toastr.error(err.response.data.code, er))
        return Promise.reject(err.response)
      } else if (err.response.status === 500) {
        console.log('Server Error', JSON.stringify(err.response));
        err.response.data.errors.forEach((e) => {
          toastr.error(e.error, e.message)
        })
        return Promise.reject(err.response)
      }
    }catch(err){
      history.push("/500");
      return;
    }
    
    
  });
}
