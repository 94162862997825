export const LOGIN = 'LOGIN';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const TAXI_LIST = "TAXI_LIST";
export const GRAPH_FILTERS = 'GRAPH_FILTERS';
export const GOOGLE_MAP_KEY= "AIzaSyBUwo8oLC54lQ_oa3mm39bReGvd4tlp1lM";
export const GOOGLE_HEAT_MAP_GRADIENT = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)"
  ];