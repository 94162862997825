import { combineReducers } from "redux";
import taxiReducer from "./taxi";
import authReducer from "./auth";
import graphFilterReducer from "./graph-filter";
import {reducer as toastrReducer} from 'react-redux-toastr'

const appReducer = combineReducers({
  taxis: taxiReducer,
  auth: authReducer,
  graphFilter: graphFilterReducer,
  toastr: toastrReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'UNAUTHORIZED') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer;