import { GRAPH_FILTERS } from "../constants";
import moment from 'moment';
export default function (state = {
  showLiveTrackingGraph: {
    timeline: false,
    region: true
  },
  showAirtime: {},
  showImpressionCount: {},
  showTaxiStatus: {},
  
  startDate: moment().startOf('day').valueOf(),
  endDate: moment().add(1,'days').valueOf(),
  format: 'hour'
}, action) {
  switch (action.type) {
    case GRAPH_FILTERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}